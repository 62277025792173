import React, { useEffect, useState } from 'react';
import "../assets/css/Home.scss";
import axios from 'axios';

import radarImg from "../assets/image/banner/radar-anim.gif";

function Counter({ id, start, end, duration }) {
  const [current, setCurrent] = useState(start);

  useEffect(() => {
    let timer;
    const increment = end > start ? 1 : -1;
    const range = Math.abs(end - start);
    const step = Math.abs(Math.floor(duration / range));

    if (current !== end) {
      timer = setInterval(() => {
        setCurrent(prevCurrent => prevCurrent + increment);
      }, step);
    }

    return () => clearInterval(timer);
  }, [start, end, duration, current]);

  return <span id={id}>{current}</span>;

}

function Banner() {

  const [ipAddress, setIpAddress] = useState({});
  useEffect(() => {
    axios.get('https://ipinfo.io/json')
      .then(response => {
        const { ip, city, region, country, loc, org } = response.data;
        setIpAddress(response.data)
        console.log(response.data)
        console.log('IP Address:', ip);
        console.log('Location:', city, region, country, '(', loc, ')');
        console.log('ISP:', org);
        // Handle the response data as needed
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>
      <div className="banner">
        <div className="container">

          <div className="row">
            <div className="col-lg-8">
              <div className="banner-content position-relative text-white">
                <div className="banner-lhs">

                  <h1 className="welcome-txt color-primary" data-aos="fade-down">  <Counter id="count1" start={0} end={1407} duration={1000} />  users</h1>

                  {/* <div className='banner-anim welcome-txt mb-4'>
                        <h1 className="mt-1 welcome-txt mb-4"> 
                          <span data-text="have gone off radar">have gone off radar</span>
                        </h1>
                    </div> */}

                  <h1 className="mt-1 welcome-txt mb-4" data-aos="fade-down">have gone off radar</h1>
                  <div className="row" data-aos="fade-right">
                    <div className="col-md-4 mb-lg-0 mb-2">
                      <div className="banner-sm-txt">
                        <span className="fw-bold mr-1">Your IP: </span>
                        <span className='pl-1'>{ipAddress && ipAddress.ip ? ipAddress.ip : "149.168.123.132"}</span>
                      </div>
                    </div>
                    <div className="col-md-4  mb-lg-0 mb-2">
                      <div className="banner-sm-txt">
                        <span className="fw-bold mr-1">Location: </span>
                        <span className="pl-1"> {ipAddress && ipAddress.city ? ipAddress.city : 'Sheffield'},{ipAddress && ipAddress.region ? ipAddress.region + ',' : ''} {ipAddress && ipAddress.country ? ipAddress.country : 'UK'}</span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="banner-sm-txt">
                        <span className="fw-bold mr-1">ISP: </span>
                        <span>{ipAddress && ipAddress.org ? ipAddress.org : 'Vodafone'}</span>
                      </div>
                    </div>
                  </div>
                  <div className=" pt-4 mt-2" data-aos="fade-right">
                    <a href="https://app.uniswap.org/swap?outputCurrency=0xf18A123ff479ff30336302Bded29696Ead2F3411&chain=ethereum" target='_blank' className="btn btn-outline sq-btn">
                      <span> Buy $ORV </span>
                    </a>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-4 position-relative" data-aos="fade-left">
              {/* <div className='radar-wrap'>
                <img src={radarImg} />
              </div> */}
              <div className="banner-rhs-img"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;

