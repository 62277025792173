import React, { useState } from 'react';
import "../assets/css/Home.scss";
import twiter from '../assets/image/icons/Vector.png';
import telegram from '../assets/image/icons/telegram.png';
import { Button, Modal } from 'react-bootstrap';
function PrivacyPolicy() {
   return (
    <>
    <div className='container'>
      <section className='p-4'>
      <h1 className="mt-1 text-large-md mb-3">Privacy Policy for Offradar dVPN</h1>
      <div data-aos="fade-down" data-aos-delay='100'>
        <div className='fw-bold mb-2'>Introduction</div>
        <p>Welcome to Offradar dVPN. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you visit our app and tell you about your privacy rights and how the law protects you.</p>
      </div>
       
       <div data-aos="fade-down" data-aos-delay='200'>
        <div className='fw-bold mb-2'>Important Information</div>
          <p>This privacy policy aims to give you information on how Offradar dVPN collects and processes your personal data through your use of this app, including any data you may provide through this app when you use our service.</p>
       </div>
       
        <div  data-aos="fade-down" data-aos-delay='300'>
          <div className='fw-bold mb-2'>Third-party Links</div>
          <p>This app may include links to third-party websites, plug-ins, and applications. Clicking on those links may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements.</p>
        </div>

        <div  data-aos="fade-down" data-aos-delay='600'>
          <div className='fw-bold mb-2'>The Data We Collect About You</div>
          <p>Offradar dVPN does not collect any personal data, period. We operate with a strict no-logs policy, ensuring your use of our services is private and unrecorded.</p>
        </div>

        <div  data-aos="fade-down" data-aos-delay='900'>
        <div className='fw-bold mb-2'>Data Security</div>
        <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way. In addition, we limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know.</p>
        </div>
        <div  data-aos="fade-down" data-aos-delay='1200'>
          <div className='fw-bold mb-2'>Your Legal Rights</div>
          <p data-aos="fade-down">Under certain circumstances, you have rights under data protection laws in relation to your personal data, such as the right to request access, request correction, and request erasure of your personal data..</p>
        </div>
        <div  data-aos="fade-down" data-aos-delay='1500'>
          <div className='fw-bold mb-2'>How to Contact Us</div>
          <p>If you have any questions about this privacy policy or our privacy practices, please contact us at:</p>
          <div>Email:<a href="mailto:luckyeth20@gmail.com">luckyeth20@gmail.com</a> </div>
        </div>


       
       
        
      </section>
      </div>
    </>
  )
}

export default PrivacyPolicy
