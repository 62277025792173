import React, { useEffect, useState } from 'react';
import "../assets/css/Home.scss";
import WorldMap from "./Map/WorldMap";

function Counter({ id, start, end, duration }) {
  const [current, setCurrent] = useState(start);

  useEffect(() => {
    let timer;
    const increment = end > start ? 1 : -1;
    const range = Math.abs(end - start);
    const step = Math.abs(Math.floor(duration / range));

    if (current !== end) {
      timer = setInterval(() => {
        setCurrent(prevCurrent => prevCurrent + increment);
      }, step);
    }

    return () => clearInterval(timer);
  }, [start, end, duration, current]);

  return <span id={id}>{current}</span>;

}

function PageNetwork() {

  return (
    <div className="pt-5 dark-bg">
    <div className="container text-white">
      <div className="row align-items-start justify-content-between">
      <div className="col-lg-4" >
        <div className="network-heading">
          <div className="n-label">Network status</div>
          <div className="n-title"> Fully operational</div>           
        </div>
        <div className="d-flex mb-5 hover-txt">
          <h1 className="text-large mb-0"><Counter id="count1" start={0} end={42} duration={1000} /></h1>
          <div className="btm-txt"> nodes</div>        
        </div>
        <div className="d-flex mb-5 hover-txt">
          <h1 className="text-large mb-0"><Counter id="count2" start={0} end={26} duration={1000} /></h1>
          <div className="btm-txt"> countries</div>        
        </div>
        <div className="d-flex mb-5 hover-txt">
          <h1 className="text-large mb-0"><Counter id="count3" start={0} end={782} duration={1000} /></h1>
          <div className="btm-txt"> users</div>        
        </div>
        </div>
        <div className="col-lg-8">
         <div>
          {/* <h1>Map here</h1> */}
          
        <WorldMap />
         </div>
        </div>
       
      </div>
    </div>
  </div>
  );
}

export default PageNetwork;
