import React , { useState, useEffect } from 'react';
import { BrowserRouter , Switch,Route} from 'react-router-dom';
import Banner from "../compontents/banner";
import FunctionsComponents from "../compontents/FunctionsComponents";
import PageNetwork from "../compontents/pageNetwork";
import AppPage from "../compontents/AppPage";
import PageToken from "../compontents/PageToken";
import logo from '../assets/image/logo/logo.svg';
import mobileLogo from '../assets/image/logo/mobile-logo.svg';
import Partner from "../compontents/Map/Partner";
import { Parallax } from 'react-scroll-parallax';
import { FaTimes, FaBars} from "react-icons/fa";
import twiter from '../assets/image/icons/Vector.png';
import telegram from '../assets/image/icons/telegram.png';
function Home() {

  const [activeLink, setActiveLink] = useState('');
  const [collapsed, setCollapsed] = useState(true);


  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {

      const url = window.location.href;
      const handleHashChange = () => {
        const fragment = window.location.hash;
        setActiveLink(fragment);
    };

    // Set active link on initial load
    handleHashChange();

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup function
    return () => {
        window.removeEventListener('hashchange', handleHashChange);
    };
  })
 
  return (
   
    <div>
      
    <div className='position-relative'>
        <div className='mobile-nav'>
          <div className="navbar-toggle d-lg-none d-inline-block mobile-close" onClick={toggleNavbar}>
              {isOpen ? <FaTimes /> : <FaBars />}
          </div>
          <a className="navbar-brand  d-lg-none d-inline-block mobile-logo" href="/"><img src={mobileLogo} alt='imagein Logo'/></a>
        </div>
         
      <nav className={`navbar navbar-expand-lg fixed-top custom-navbar ${isOpen ? 'show' : ''}`}>
        
        <div className="container">
          <a className="navbar-brand d-none d-lg-inline-block" href="/"><img src={logo} alt='imagein Logo'/></a>       
          <div className='d-flex justify-content-between w-100 flex-column flex-lg-row'>
          {/* <a className="d-lg-none d-inline-block mobile-close" href="javascript:;" onClick={toggleNavbar}><FaTimes /></a> */}
            <ul className="navbar-nav ">
              <li className="nav-item">
               <a className={`nav-link ${activeLink === '#function' ? 'active' : ''}`} href="#function" onClick={toggleNavbar}>Function</a>
              </li>
              <li className="nav-item">
               <a className={`nav-link ${activeLink === '#partner' ? 'active' : ''}`} href="#partner" onClick={toggleNavbar}>Partners</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${activeLink === '#appPage' ? 'active' : ''}`} href="#appPage" onClick={toggleNavbar}>App</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${activeLink === '#pagetoken' ? 'active' : ''}`} href="#pagetoken" onClick={toggleNavbar}>Token</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${activeLink === '#pagenetwork' ? 'active' : ''}`} href="#pagenetwork" onClick={toggleNavbar}>Network</a>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto px-4 px-lg-0">
              <li className="nav-item">
              <a href="javascript:;" className="btn btn-outline sq-btn top-btn mt-md-0 mt-4">
                <span>GET off Radar VPN</span>
              </a>
              </li>
            </ul>          
          </div>
          
        </div>
      </nav> 
      </div>
     
      <Parallax
        className="custom-class"
        offsetYMax={20}
        offsetYMin={-20}
        slowerScrollRate
        tag="figure"
    >
       <div> <Banner /> </div>
    </Parallax>

    <Parallax
        className="custom-class"
        offsetYMax={20}
        offsetYMin={-20}
        slowerScrollRate
        tag="figure"
    >
      <div id="function" className='home-section bg-gray'> <FunctionsComponents /> </div> 
    </Parallax>

    <Parallax
        className="custom-class"
        offsetYMax={20}
        offsetYMin={-20}
        slowerScrollRate
        tag="figure"
    >
      <div id="partner" className='home-section px-0 p-0'> <Partner /> </div> 
    </Parallax>

    <Parallax
        className="custom-class"
        offsetYMax={20}
        offsetYMin={-20}
        slowerScrollRate
        tag="figure"
    >
       <div id="appPage" className='home-section bg-gray'> <AppPage /> </div> 
    </Parallax>

    <Parallax
        className="custom-class"
        offsetYMax={20}
        offsetYMin={-20}
        slowerScrollRate
        tag="figure"
    >
       <div id="pagetoken" className='home-section dark-bg'> <PageToken /> </div> 
    </Parallax>

    <Parallax
        className="custom-class"
        offsetYMax={20}
        offsetYMin={-20}
        slowerScrollRate
        tag="figure"
    >
       <div id="pagenetwork" className='home-section dark-bg'><PageNetwork /></div>
        </Parallax>

 

   
    </div>
    
  );
}

export default Home;
