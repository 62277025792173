import log1 from "../../assets/image/logo/Frame 115.svg"
import log2 from "../../assets/image/logo/Frame 116.svg"
import log3 from "../../assets/image/logo/Frame 114.svg"
function PageToken() {
  return (
    <>
      <div className="partnerBackround">
        {/* <h1 className="outline-font text-large" data-aos="fade-down">Your app</h1>  */}
        <h1 className="mt-3 text-large mb-4 text-center" data-aos="fade-right">Official partners</h1>

        {/* <h2 className="text-center partnerTitle">Official partners</h2> */}
        <div className="row align-items-center justify-content-center">

          <div className="col-lg-1" data-aos="fade-down" >
            <img src={log3} className="img-fluid" />
          </div>
          <div className="col-lg-1" data-aos="fade-down">
            <img src={log2} className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  )
}
export default PageToken;