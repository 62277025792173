import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivacyPolicy from '../compontents/PrivacyPolicy';
import Terms from '../compontents/TermsandConditions';

const AppRouter = () => {
  return (
    <Routes>
      
      <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
      <Route path="/termsofuse" element={<Terms/>} />



    </Routes>
  );
};

export default AppRouter;
