import DottedMap from "dotted-map";
import countryData from './Country.json';
import { useEffect } from "react";

const WorldMap = () => {    
    useEffect(() => {
        const map = new DottedMap({ height: 60, grid: "vertical" });
        const start = async () => {
            let selectedCountry = [
                'AF', 'UA', 'BV', 'CL',
                'BF', 'KP', 'AU', 'CN',
                'DO', 'BI', 'FO', 'IR',
                'AX', 'AO', 'AT', 'GA',
                'HU', 'SM', 'IN', 'GB'
            ];
            for (let item of selectedCountry) {
                let country = countryData.find(country => country.key === item);
                if (country) {
                    map.addPin({
                        lat: country.latitude,
                        lng: country.longitude,
                        svgOptions: { color: "#B3F700", radius: 0.5 }
                    });
                }
            }
            const svgMap = map.getSVG({
                radius: 0.35,
                color: "#313131",
                shape: "circle",
                backgroundColor: "#000000"
            });
            const img = document.getElementById("map-img");
            img.src = `data:image/svg+xml;utf8,${encodeURIComponent(svgMap)}`;
        }
        start();
    }, []);

    return (
        <div>
            <img
                id="map-img"
                alt=""
            />
        </div>
    );
};

export default WorldMap;
