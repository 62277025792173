import './App.scss';
import Home from './screens/Home';
import Footer from './compontents/footer';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { BrowserRouter as Router } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import React, { useEffect, useState } from 'react';
import AppRouter from './routes/route';

function App() {
  const [pathName, setPathName] = useState(window.location.pathname);

  useEffect(() => {
    AOS.init({
      duration:450
    });
  }, []); // Initialize AOS only once on component mount

  return (
    <div>
      <Router>
        {
          pathName && (pathName === '/privacypolicy' || pathName === '/termsofuse') ? (
            <AppRouter />
          ) : (
            <>
              <ParallaxProvider>
                <Home />
              </ParallaxProvider>
              <Footer />
            </>
          )
        }
      </Router>
    </div>
  );
}

export default App;
