import React, { useState } from 'react';
import "../assets/css/Home.scss";
import twiter from '../assets/image/icons/Vector.png';
import telegram from '../assets/image/icons/telegram.png';
import { Button, Modal } from 'react-bootstrap';
function TermsandConditions() {
   return (
    <>
    <div className='container'>
      <section className='p-4'>
      <h1 className="mt-1 text-large-md mb-3" data-aos="fade-down">Terms of Use for Offradar dVPN</h1>
      
      <div data-aos="fade-down" data-aos-delay='300'>
        <div className='fw-bold mb-2'>Introduction</div>
        <p>These terms and conditions outline the rules and regulations for the use of Offradar dVPN's App, located at Offradar dVPN.</p>
        <p>By accessing this app, we assume you accept these terms and conditions. Do not continue to use Offradar dVPN if you do not agree to take all of the terms and conditions stated on this page.</p>
      </div>

      <div data-aos="fade-down" data-aos-delay='600'>
      <div className='fw-bold mb-2'>License</div>
        <p>Unless otherwise stated, Offradar dVPN and/or its licensors own the intellectual property rights for all material on Offradar dVPN. All intellectual property rights are reserved.</p>
        <div data-aos="fade-down" data-aos-delay='900'>
          <div className='fw-bold mb-2'>You must not:</div>
          <div>Republish material from Offradar dVPN</div>
          <div>Sell, rent, or sub-license material from Offradar dVPN</div>
          <div>Reproduce, duplicate, or copy material from Offradar dVPN</div>
          <div>Redistribute content from Offradar dVPN</div>
        </div>
       
      </div>

      <div data-aos="fade-down" data-aos-delay='1200'>
        <div className='fw-bold mb-2 mt-3'>Limitation of Liability</div>
        <p>Our service is provided "as is" and "as available" without any representation or warranty, whether express, implied, or statutory. Offradar dVPN specifically disclaims any implied warranties of title, merchantability, fitness for a particular purpose and non-infringement.</p>
      </div>

      <div data-aos="fade-down" data-aos-delay='1500'>
        <div className='fw-bold mb-2'>Governing Law & Jurisdiction</div>
        <p>These Terms will be governed by and interpreted in accordance with the laws of the location of our headquarters, and you submit to the non-exclusive jurisdiction of the state and federal courts located in our jurisdiction for the resolution of any disputes.</p>
        <p>These documents are templates and may need to be adjusted based on local laws and specific circumstances related to your app. It's often advisable to consult with a legal professional to ensure full compliance with local regulations and legal standards.</p>
      </div>

       
      </section>
      </div>
      
    </>
  )
}

export default TermsandConditions
