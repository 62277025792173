import React, { useState } from "react";
import "../assets/css/Home.scss";
function PageToken() {
  const [data, setData] = useState([
    {
      name: 'Liquidity',
      value: 60
    }, {
      name: 'Staking pool',
      value: 10
    },
    {
      name: 'Seed',
      value: 20
    }, {
      name: 'Team',
      value: 5
    }, {
      name: 'Ecosystem',
      value: 5
    }
  ])
  const [total, setTotal] = useState(300)
  const getTotal = (value) => {
    let divs = []
    let divCount = (value / 100) * total;
    for (let i = 0; i < divCount - 1; i++) {
      divs.push(<div key={i} className="dot mb-1"></div>);
    }
    return divs
  }
  return (
    <>
      <div className=" ">
        <div className="container">
          <div className="w-100 token-table">
            <div className="row align-items-end">
              <div className="col-lg-8 order-2 order-md-1">
                <div className="text-white mb-5 pr-lg-5 mt-lg-0 pt-lg-0" data-aos="fade-right">
                  <h1 className="mt-1 text-large1 mb-kg-5 mb-3">Token</h1>
                  <p className=" pt-4 mt-2 token-label pr-lg-5" data-aos="fade-down">
                    The ERC-20 token Off Radar AI ($ORV) is the lifeblood of the network and vital for the ecosystem of Off Radar to function well.
                  </p>
                  <p data-aos="fade-down" className="token-label pr-lg-5">Trading of the token on the Ethereum blockchain sustains the development of the project through a 5/5 tax mechanism, where 2% goes to development and 3% is returned to the ecosystem. Development of the project in turn sustains the trading through additional burn mechanisms.</p>
                </div>
              </div>
              {/*               <div className="col-lg-4 hover-txt order-1 order-md-2">
                <h1 className="mt-md-1 pt-md-0 mt-4 pt-3 text-large outline-light-font mb-md-4 mb-2 ">$ORV</h1>
                <div className="token-val mb-4 ">0xf18a123ff479ff30336302bded29696ead2f3411</div>
              </div>
            </div> */}
              <div className="col-lg-4 hover-txt order-1 order-md-2">
                <h1 className="mt-md-1 pt-md-0 mt-4 pt-3 text-large outline-light-font mb-md-4 mb-2">$ORV</h1>
                <div className="token-val mb-4">
                  <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x2971b83d0665d93447a91b8e1c4e8648b538305d?t=1717691090634" target="_blank">0xf18a123ff479ff30336302bded29696ead2f3411</a>
                </div>
              </div>
            </div>


            {data && data.length > 0 ? (
              data.map(item => (
                <div key={item.id} className="row token-row">
                  <div className="col-lg-8">
                    <u className="w-100 token-list pt-5">
                      <li className="d-flex justify-content-between">
                        <div className="token-label">{item.name}</div>
                        <div className="token-val">{String(item.value).padStart(2, '0')}%</div>
                      </li>
                    </u>
                  </div>
                  <div className="col-lg-4">
                    <div className="w-100 pb-5">
                      <div style={{ paddingLeft: 10 }} className="d-flex flex-wrap">
                        {getTotal(item.value)}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}

          </div>

          {/* <table className="w-100 token-table">

					{data && data.length > 0 ? (
              data.map(item => (
                <tr key={item.id}>
                  <td style={{ width: '70%' }}>
                    <u className="w-100 token-list pt-5">
                      <li className="d-flex justify-content-between">
                        <div className="token-label">{item.name}</div>
                        <div className="token-val">{String(item.value).padStart(2, '0')}%</div>
                      </li>
                    </u>
                  </td>
                  <td>
                    <div className="w-100 pb-5">
                      <div style={{paddingLeft:10}} className="d-flex flex-wrap">
                        {getTotal(item.value)}
                      </div> 
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <></> 
            )}

					</table> */}

        </div>
      </div>
    </>
  );
}

export default PageToken;
