import React from "react";
import "../assets/css/Home.scss";
import appImg from "../assets/image/home/appImg.svg"
function AppPage() {
  return (
    <div className="">
    <div className="container">
      <div className="row align-items-center justify-content-between">
      <div className="col-lg-6 text-center" >
            <img src={appImg}  className="w-100" data-aos="fade-down"/>
        </div>
        <div className="col-lg-6 px-lg-3 px-0 mt-lg-0 mt-4 pt-3  pt-lg-0">
          <div className="content-txt">
          <div className="mx-3 pl-5 pb-4">
                <h1 className="outline-font text-large" data-aos="fade-down">Your app</h1> 
                <h1 className="mt-3 text-large mb-4" data-aos="fade-right">Your gateway</h1>
                <div >
                <p className="" data-aos="fade-down">
                   Off Radar understands that privacy and security shouldn't come at a premium. Unlike many competitors who charge exorbitant fees for their services, Off Radar offers competitive pricing plans that make privacy accessible to everyone. 
                </p>
                <p data-aos="fade-down">Our goal is to make Off Radar the most affordable dVPN on the market</p>               
                <p data-aos="fade-down">No logs, no tracking - just completely accessible privacy.</p>
                </div>
              </div> 
              <div className=" pt-4 mt-2" data-aos="fade-down">
                <a href="javascript:;" className="btn btn-outline secondary-btn mobile-btn">
                  <span>GET OFF RADAR VPN</span>
                </a>
              </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
  );
}

export default AppPage;
