import React, { useState } from 'react';
import "../assets/css/Home.scss";
import twiter from '../assets/image/icons/Vector.png';
import telegram from '../assets/image/icons/telegram.png';
import dextools from '../assets/image/icons/dextools.png';
import medium from '../assets/image/icons/medium.png';

import { Button, Modal } from 'react-bootstrap';
function Footer() {
  return (
    <>
      <footer>
        <section class="footer">
          <div className='d-flex align-items-center flex-wrap justify-content-between'>
            <div className='mr-lg-5 pr-4'>
              Copyright © 2024 <a href="https://www.offradarvpn.com/">off radar</a> All Rights Reserved.
            </div>
            <div className='my-md-0 my-2'>
              <a href="mailto:luckyeth20@gmail.com" target='_blank' style={{ marginRight: '25px' }} className='ms-4 d-inline-block'>Contact Us</a>
              <a href="/privacypolicy" className='me-4 d-inline-block' target='_blank'>Privacy Policy</a>
              <a href="/termsofuse" target='_blank'>Terms of Use</a>
            </div>
            <div class="social">
              <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x2971b83d0665d93447a91b8e1c4e8648b538305d?t=1717691090634" target='_blank'><img class="insta" src={dextools} alt="Dextools" style={{ width: '50px' }} /></a>
              <a href="https://twitter.com/offradarvpn" target='_blank'><img class="insta" src={twiter} alt="twiter" style={{ width: '50px' }} /></a>
              <a href="https://t.me/offradarvpn" target='_blank'><img class="insta" src={telegram} alt="telegram" style={{ width: '50px' }} /></a>
              <a href="https://medium.com/@luckyeth20/off-radar-revolutionizing-online-privacy-with-a-decentralized-vpn-b643b07674a3" target='_blank'><img class="insta" src={medium} alt="Medium" style={{ width: '50px' }} /></a>

            </div>
          </div>

        </section>
      </footer>
    </>
  )
}

export default Footer
