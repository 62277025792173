import React from "react";
import "../assets/css/Home.scss";
import funcImg from "../assets/image/home/function-group.gif"
function FunctionsComponents() {
  return (
    <>
      <div className="">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <div className="content-txt">
              <div className="pt-lg-5 mt-lg-5 mt-4 pt-3" data-aos="fade-right">
                    <h1 className="text-large outline-font" >How does</h1> 
                    <h1 className="mt-1 text-large mb-4">it work?</h1>
                    <p className=" pt-4 mt-2" data-aos="fade-down">
                       Instead of routing traffic through a central server, Off Radar enables you to quickly and easily route your data through any available decentralized node on the network to gain full privacy.
                    </p>
                    <p data-aos="fade-down">Unlike centralized VPNs that are susceptible to hacks, breaches, and government surveillance, Off Radar's decentralized architecture minimizes vulnerabilities and protects your privacy with military-grade encryption and zero-log policies.</p>
                    <p data-aos="fade-down">Node providers are paid a steady rate of $ORV by the network for hosting this encrypted service. Neither node providers or the team at Off Radar can ever access your encrypted data.</p>
                    <p data-aos="fade-down">Got more questions? Have a look at our extensive whitepaper.</p>
                  </div> 
                  <div className="pt-4 mt-2" data-aos="fade-right">
                      <a href={require("../assets/pdf/OFF-Rador-White-Paper-v1.pdf")} target="_blank"  className="btn btn-outline secondary-btn mobile-btn">
                        <span>Read the whitepaper </span>
                      </a>
                    </div>
              </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-4 pt-3 pt-lg-0 pr-0 d-flex justify-lg-content-end" data-aos="zoom-in-up">
                <img src={funcImg}  className="mobile-img"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FunctionsComponents;
